import { AppSessionId, SavedViewId } from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { DisplayTableFilterSessionStateMpFragment } from "../../components/saved-views/queries.generated.js";
import { RootState } from "../store.js";

import {
  FilterCellSessionStateMP,
  ParameterValueMP,
  SharedFilterSessionStateMP,
} from "./appSessionMPSlice.js";

interface SavedViewDefaultAppSession {
  // The "base" data for the saved view that is currently selected, based on user
  // interaction of editing or selecting a new view.
  id: SavedViewId;
  isEditing: boolean;

  // The saved view data that is currently selected. This data is fetched from the server when
  // toggling between views, and is used to populate the saved view changes dropdown or local changes.
  savedView?: {
    name: string;
    shared: boolean;
    defaultAppSessionId: AppSessionId;
    defaultState: {
      parameterValues: ParameterValueMP[];
      filterCellSessionStates: FilterCellSessionStateMP[];
      displayTableFilterStates: DisplayTableFilterSessionStateMpFragment[];
      sharedFilterSessionStates: SharedFilterSessionStateMP[];
    };
  };
}

const savedViewDefaultAppSessionSlice = createSlice({
  name: "savedViewDefaultAppSession",
  initialState: null as SavedViewDefaultAppSession | null,
  reducers: {
    /**
     * Use this reducer when initializing the saved view default app session state,
     * once its been fetched from the server.
     */
    initializeSavedViewDefaultAppSession(
      state,
      action: PayloadAction<Omit<
        SavedViewDefaultAppSession,
        "isEditing"
      > | null>,
    ) {
      if (action.payload == null) {
        return null;
      }
      return {
        ...action.payload,
        // Respect the previous editing state when initializing the saved view default app session state.
        isEditing: state?.isEditing ?? false,
      };
    },
    setSavedViewId(state, action: PayloadAction<SavedViewId | null>) {
      if (action.payload == null) {
        return null;
      }

      const isSameViewId = state?.id === action.payload;
      return {
        // clear the data for the savedView if we are changing the savedViewId.
        ...(isSameViewId ? state : null),
        isEditing: isSameViewId ? state.isEditing : false,
        id: action.payload,
      };
    },
    enableEditing(state, action: PayloadAction<SavedViewId>) {
      return {
        ...(state?.id === action.payload ? state : null),
        id: action.payload,
        isEditing: true,
      };
    },
    disableEditing(state) {
      if (state) {
        state.isEditing = false;
      }
    },
  },
});

export const savedViewDefaultAppSessionSelectors = {
  selectParameterValues: (state: RootState) =>
    state.savedViewDefaultAppSession?.savedView?.defaultState.parameterValues ??
    [],
  selectFilterCellSessionStates: (state: RootState) =>
    state.savedViewDefaultAppSession?.savedView?.defaultState
      .filterCellSessionStates ?? [],
  selectSharedFilterSessionStates: (state: RootState) =>
    state.savedViewDefaultAppSession?.savedView?.defaultState
      .sharedFilterSessionStates ?? [],
  selectCurrentSavedViewId: (state: RootState) =>
    state.savedViewDefaultAppSession?.id,
  selectIsEditing: (state: RootState) =>
    state.savedViewDefaultAppSession?.isEditing ?? false,
  selectDisplayTableFilterStates: (state: RootState) =>
    state.savedViewDefaultAppSession?.savedView?.defaultState
      .displayTableFilterStates ?? [],
};

export const savedViewDefaultAppSessionActions = {
  ...savedViewDefaultAppSessionSlice.actions,
};

export const {
  disableEditing,
  enableEditing,
  initializeSavedViewDefaultAppSession,
  setSavedViewId,
} = savedViewDefaultAppSessionSlice.actions;

export const savedViewDefaultAppSessionReducer =
  savedViewDefaultAppSessionSlice.reducer;
